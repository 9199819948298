<template>
  <div
    class="credit-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-index.png')" />
      <div class="credit-info">
        <div class="credit">
          {{ PAGELANG.credit }}
          <span class="num price">{{FiltergoodsMoney}}</span>
        </div>
        <div class="other">
          <div class="item">
            {{ PAGELANG.累计购货额 }}
            <span class="num price">{{FilterinMoney}}</span>
          </div>
          <div class="item">
            {{ PAGELANG.已提货物价值 }}
            <span class="num price">{{FilteroutMoney}}</span>
          </div>
        </div>
      </div>
    </div>

    <ul class="credit-menu">
      <li>
        <a href="#/credit/logs">
          <span class="title">
            <i class="iconfont icon-qianbao-02" style="color: #f5a624" />
            {{ PAGELANG.货款明细 }}
          </span>
          <span class="price">{{FiltergoodsMoney}}</span>
        </a>
      </li>
      <li>
        <a @click="toCredit()">
          <span class="title">
            <i class="iconfont icon-qianbao-04" style="color: #3cadff" />
            {{ PAGELANG.类型充值 }}
          </span>
        </a>
      </li>
      <li>
        <a href="#/credit/rechargelogs">
          <span class="title">
            <i class="iconfont icon-jilu" style="color: #3cadff" />
            {{ PAGELANG.充值记录 }}
          </span>
        </a>
      </li>
    </ul>
  </div>

<!--  <div
    class="pc-my-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <MyMenu :agentInfo="agentInfo" pagename="credit" />
    <div class="main">
      <div class="credit-info">
        <div class="item">
          <span class="num price">{{ agentInfo.Balance}}</span>
          {{ PAGELANG.credit }}
        </div>
        <div class="item">
          <span class="num price">{{ agentInfo.Credit }}</span>
          {{ PAGELANG.累计购货额 }}
        </div>
        <div class="item">
          <span class="num price">{{ agentInfo.Expend }}</span>
          {{ PAGELANG.已提货物价值 }}
        </div>
      </div>
      <ul class="credit-menu">
        <li>
          <a href="#/credit/logs">
            <span class="title">
              <i class="iconfont icon-qianbao-02" />
              {{ PAGELANG.货款明细 }}
            </span>
            <span class="price">{{ agentInfo.Balance }}</span>
          </a>
        </li>
        <li>
          <a href="#/credit/recharge">
            <span class="title">
              <i class="iconfont icon-qianbao-04" />
              {{ PAGELANG.类型充值 }}
            </span>
          </a>
        </li>
        <li>
          <a href="#/credit/rechargelogs">
            <span class="title">
              <i class="iconfont icon-jilu" />
              {{ PAGELANG.充值记录 }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div -->>
</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
import { getCookie } from "../util";
// import MyMenu from "../components/MyMenu.vue";

export default {
  // components: {
  //   MyMenu,
  // },
  computed:{
	   FiltergoodsMoney() {
	        return this.agentInfo.goodsMoney/100
	   },
	   FilterinMoney(){
		   return this.agentInfo.inMoney/100
	   },
	   FilteroutMoney(){
		   return this.agentInfo.outMoney/100
	   }
		 
  },
  data() {
    return {
      agentInfo: {},
	  goCredit:true,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	this.axios.get(this.actions.offlineList,{params:{
		status:1,
		page:1,
		pageSize:15		
		}}).then((res) => {
			if(res.data.data.list.length>0){
				this.goCredit=false
			}else{
			    this.goCredit=true
		    }		 
	})
	

    this.PAGELANG = this.LANG.pages.recharge;
    this.SYSLANG = this.LANG.system;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.axios
      .get(this.actions.memberMoney)
      .then((res) => {
        closeToast();
        let { code, message, data } = res.data;
        console.log(code, message);
        if (code == 200) {
          this.agentInfo = data;
        } else {
          showToast({
            message:
              code == "NOTLOGIN" ? this.SYSLANG[message] : this.PAGELANG[message],
            forbidClick: true,
            onClose: () => {
              if (code == "NOTLOGIN") {
                this.$router.replace({
                  path: "/login",
                });
              }
            },
          });
        }
      })
      .catch((error) =>
        //请求失败，触发catch中的函数 可省略
        console.log(error)
      );
  },
  methods:{
	  toCredit(){
		  if(this.goCredit==false){
		  	showToast({
		  		message:"已有申請在審核中,請勿重複提交!",
		  		
		  	});
		  	return
		  }else{
		  	this.$router.push({
		  	  path: "/credit/rechargeMoney",
		  	});
		  }
	  }
  }
};
</script>


<style src="../assets/css/credit.css" scoped></style>